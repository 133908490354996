@use "sass:list";
@use "sass:map";
@use "../../functions" as *;
@use "../../tokens" as *;

// Outputs text-decoration and color properties

@mixin u-text-decoration($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  @each $this-value in $value {
    @if map.has-key($all-color-shortcodes, smart-quote($this-value)) {
      text-decoration-color: color(smart-quote($this-value)) #{$important};
    } @else {
      text-decoration: get-uswds-value("text-decoration", $value...)
        #{$important};
    }
  }
}

@use "uswds-core" as *;

.usa-hint {
  @extend %block-input-general;
  color: color("base");
}

.usa-hint--required {
  color: color("error-dark");
}

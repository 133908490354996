@use "uswds-core" as *;

.usa-textarea {
  @extend %block-input-general;
  @extend %block-input-styles;

  &:disabled,
  &[aria-disabled="true"] {
    @include u-disabled;
    @include u-disabled-high-contrast-border;
  }
}

.usa-textarea {
  height: units("card");
}

/*
----------------------------------------
Set basic font rules for the font
utilities to reference.
----------------------------------------
*/

@use "sass:map";
@use "sass:list";
@use "uswds-core" as *;

$if-important: "";

@if $utilities-use-important {
  $if-important: " !important";
}

// Generate font rules if the `font` utility is on the uselist
// and its output is set to true
@if (
  (
      $output-these-utilities ==
        "default" or
        list.index($output-these-utilities, "font")
    ) and
    map.get($font-settings-complete, "output") ==
    true
) {
  @each $face, $stack in $project-font-stacks {
    @if $stack {
      [class*="#{ns('utility')}font-#{$face}-"] {
        font-family: #{$stack}#{$if-important};
      }
    }
  }
}

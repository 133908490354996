@use "uswds-core" as *;

.usa-prose {
  @include typeset($theme-prose-font-family);

  & > {
    @include usa-list-styles;
    @include usa-table-styles;

    p {
      @include typeset-p-base;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include typeset-heading-base;
    }

    * + * {
      margin-top: $theme-paragraph-margin-top;
      margin-bottom: 0;
    }

    * + {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: $theme-heading-margin-top;
      }
    }

    // Replaces styles from usa-headings-styles
    h1 {
      @include h1;
    }
    h2 {
      @include h2;
    }
    h3 {
      @include h3;
    }
    h4 {
      @include h4;
    }
    h5 {
      @include h5;
    }
    h6 {
      @include h6;
    }
  }
}

@mixin prose-test {
  .usa-prose {
    & > {
      p {
        @include typeset-p-base;
      }

      h1 {
        margin-bottom: 0;
        margin-top: 0;
        clear: both;
      }

      * + * {
        margin-top: $theme-paragraph-margin-top;
      }

      * + h1 {
        margin-top: $theme-heading-margin-top;
      }
    }
  }
}

@use "uswds-core" as *;

.usa-display {
  @include typeset-h3;
  margin-bottom: 0;

  @include at-media("mobile-lg") {
    @include typeset-h1;
  }

  @include at-media("tablet") {
    @include typeset-display;
  }
}

@use "uswds-core" as *;

// A collection of media block objects

.usa-graphic-list {
  @include typeset;
  @include border-box-sizing;

  .usa-graphic-list__row {
    .usa-media-block {
      margin-bottom: units(4);

      @include at-media("tablet") {
        margin-bottom: units(8);
      }
    }

    &:last-child {
      .usa-media-block {
        @include at-media("tablet") {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .usa-media-block__img {
    margin-right: units(3);
  }

  .usa-media-block__body {
    > :first-child {
      margin-top: 0;
    }
  }
}

.usa-graphic-list__heading {
  @include typeset-h3;
}

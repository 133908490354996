@use "sass:list";
@use "../../functions" as *;

// Adds margin either l/r(x) or t/b(y)

@mixin margin-n($side, $value...) {
  $value: unpack($value);
  @if $side == all {
    $important: null;
    @if has-important($value) {
      $value: remove($value, "!important");
      @if list.length($value) == 1 {
        $value: de-list($value);
      }
      $important: " !important";
    }
    margin: get-uswds-value(margin, $value...) #{$important};
  } @else if $side == x {
    $important: null;
    @if has-important($value) {
      $value: remove($value, "!important");
      @if list.length($value) == 1 {
        $value: de-list($value);
      }
      $important: " !important";
    }
    margin-left: get-uswds-value("margin-horizontal", $value...) #{$important};
    margin-right: get-uswds-value("margin-horizontal", $value...) #{$important};
  } @else if $side == y {
    $important: null;
    @if has-important($value) {
      $value: remove($value, "!important");
      @if list.length($value) == 1 {
        $value: de-list($value);
      }
      $important: " !important";
    }
    margin-bottom: get-uswds-value("margin-vertical", $value...) #{$important};
    margin-top: get-uswds-value("margin-vertical", $value...) #{$important};
  } @else if $side == t {
    $important: null;
    @if has-important($value) {
      $value: remove($value, "!important");
      @if list.length($value) == 1 {
        $value: de-list($value);
      }
      $important: " !important";
    }
    margin-top: get-uswds-value("margin-vertical", $value...) #{$important};
  } @else if $side == r {
    $important: null;
    @if has-important($value) {
      $value: remove($value, "!important");
      @if list.length($value) == 1 {
        $value: de-list($value);
      }
      $important: " !important";
    }
    margin-right: get-uswds-value("margin-horizontal", $value...) #{$important};
  } @else if $side == b {
    $important: null;
    @if has-important($value) {
      $value: remove($value, "!important");
      @if list.length($value) == 1 {
        $value: de-list($value);
      }
      $important: " !important";
    }
    margin-bottom: get-uswds-value("margin-vertical", $value...) #{$important};
  } @else if $side == l {
    $important: null;
    @if has-important($value) {
      $value: remove($value, "!important");
      @if list.length($value) == 1 {
        $value: de-list($value);
      }
      $important: " !important";
    }
    margin-left: get-uswds-value("margin-horizontal", $value...) #{$important};
  }
}

@mixin u-margin($value...) {
  @include margin-n(all, $value...);
}

@mixin u-margin-x($value...) {
  @include margin-n(x, $value...);
}

@mixin u-margin-y($value...) {
  @include margin-n(y, $value...);
}

@mixin u-margin-top($value...) {
  @include margin-n(t, $value...);
}

@mixin u-margin-right($value...) {
  @include margin-n(r, $value...);
}

@mixin u-margin-bottom($value...) {
  @include margin-n(b, $value...);
}

@mixin u-margin-left($value...) {
  @include margin-n(l, $value...);
}

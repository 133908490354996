@use "sass:list";
@use "../../functions" as *;

// Outputs order

@mixin u-order($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  order: get-uswds-value("order", $value...) #{$important};
}

@use "uswds-core" as *;

@mixin range-focus {
  background-color: color("white");
  box-shadow: 0 0 0 units(2px) color($theme-focus-color);
}

@mixin range-track {
  background-color: color("base-lightest");
  border-radius: radius("pill");
  border: units(1px) solid color("base");
  cursor: pointer;
  height: units(2);
  width: 100%;
}

@mixin range-thumb {
  @include u-circle($theme-input-select-size);
  background: color("base-lightest");
  border: none;
  box-shadow: 0 0 0 units($theme-input-select-border-width) color("base");
  cursor: pointer;

  @media (forced-colors: active) {
    outline: $border-high-contrast;
  }
}

@mixin range-ms-fill {
  background-color: color("base-lightest");
  border-radius: radius("pill");
  border: units(1px) solid color("base");
}

.usa-range {
  @extend %block-input-general;
  @extend %block-input-styles;
  appearance: none;
  border: none;
  padding-left: 1px;
  width: 100%;

  &:focus {
    outline: none;

    &::-webkit-slider-thumb {
      @include range-focus;
    }

    &::-moz-range-thumb {
      @include range-focus;
    }

    &::-ms-thumb {
      @include range-focus;
    }
  }

  &::-webkit-slider-runnable-track {
    @include range-track;
  }

  &::-moz-range-track {
    @include range-track;
  }

  &::-ms-track {
    @include range-track;
  }

  &::-webkit-slider-thumb {
    @include range-thumb;
    appearance: none;
    margin-top: px-to-rem(-3px); // magic number
  }

  &::-moz-range-thumb {
    @include range-thumb;
  }

  &::-ms-thumb {
    @include range-thumb;
  }

  &::-ms-fill-lower {
    @include range-ms-fill;
  }

  &::-ms-fill-upper {
    @include range-ms-fill;
  }

  &:disabled,
  &[aria-disabled="true"] {
    // Required for mobile styles
    opacity: 1;

    &::-webkit-slider-runnable-track {
      @include u-disabled;
      @include u-disabled-high-contrast-border;
    }

    &::-moz-range-track {
      @include u-disabled;
      @include u-disabled-high-contrast-border;
    }

    &::-ms-track {
      @include u-disabled;
    }

    &::-webkit-slider-thumb {
      @include u-disabled;
    }

    &::-moz-range-thumb {
      @include u-disabled;
    }

    &::-ms-thumb {
      @include u-disabled;
    }

    &::-ms-fill-lower {
      @include u-disabled;
    }

    &::-ms-fill-upper {
      @include u-disabled;
    }
  }
}

@use "../../settings" as *;
@use "./icon" as *;
@use "../../functions" as *;
@use "./screen-reader" as *;

$external-link-size: 1.75ex;

$icon-object: (
  "name": "launch",
  "color": currentColor,
  "height": $external-link-size,
  "svg-height": 24,
  "svg-width": 24,
  "position-x": center,
  "position-y": center,
);

@mixin external-link($contrast-bg: "default") {
  display: inline;

  // Screen reader label
  &::before {
    @include sr-only;
    content: $theme-external-link-sr-label-tab-same;
  }

  &[target="_blank"]::before {
    @include sr-only;
    content: $theme-external-link-sr-label-tab-new;
  }

  &::after {
    @include add-color-icon($icon-object, $contrast-bg);
    content: "";
    display: inline;
    margin-top: 0.7ex;
    margin-left: 2px;
    padding-left: 1.75ex;
    vertical-align: middle;
  }
}

@use "sass:list";
@use "../../functions" as *;

@mixin add-list-reset($value...) {
  $important: null;
  @if list.length($value) > 0 and has-important($value) {
    $value: remove($value, "!important");
    $important: " !important";
  }
  margin-bottom: 0#{$important};
  margin-top: 0#{$important};
  list-style-type: none#{$important};
  padding-left: 0#{$important};
}

@mixin list-reset($value...) {
  @include add-list-reset($value...);
}

@use "uswds-core" as *;

$section-context: "Section";

.usa-section {
  @include border-box-sizing;
  @include u-padding-y($theme-site-margins-width);

  @include at-media("tablet") {
    @include u-padding-y(8);
  }
}

.usa-section--light {
  background-color: color("base-lightest");
}

.usa-section--dark {
  background-color: color("primary-darker");
  color: color($theme-text-reverse-color);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // TODO: This should use a theme-able color,
    // and should probably default to white
    color: color("accent-cool");
  }

  p {
    color: color($theme-text-reverse-color);
  }

  a {
    @include set-link-from-bg(
      "primary-darker",
      $theme-link-reverse-color,
      $context: $section-context
    );
  }
}

@use "../settings" as *;
@use "../functions" as *;
@use "../mixins" as *;
@use "../variables/border-high-contrast.scss" as *;

// Helpers
$input-select-margin-right: 1.5;

%block-input-general {
  @include border-box-sizing;
  @include typeset(
    $theme-form-font-family,
    $theme-body-font-size,
    $theme-input-line-height
  );
}

%block-input-styles {
  @include u-border(1px, "base-dark");
  appearance: none;
  border-radius: 0;
  color: color("ink"); // standardize on firefox
  display: block;
  height: units(5);
  margin-top: units(1);
  max-width: units($theme-input-max-width);
  padding: units(1);
  width: 100%;
}

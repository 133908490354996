@use "../../settings" as *;

// Ensure browsers that don't support SVG in background-image (IE 11 and below) fall back to PNG.
// See https://css-tricks.com/a-complete-guide-to-svg-fallbacks/

@mixin add-background-svg($image-name, $image-path: $theme-image-path) {
  background-image: url("#{$image-path}/#{$image-name}.svg"),
    linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
}

@use "../../settings" as *;

// The following vars need to be set
// here, before the rest of the system
// variables are set

$root-font-size: if($theme-respect-user-font-size, 100%, $theme-root-font-size);

$root-font-size-equiv: if(
  $theme-respect-user-font-size,
  16px,
  $theme-root-font-size
);

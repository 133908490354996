@use "sass:list";
@use "../../functions" as *;

// Outputs line-height

@mixin u-font-weight($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  font-weight: get-uswds-value("font-weight", $value...) #{$important};
}

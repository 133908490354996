@use "../utilities" as *;

@mixin button-disabled {
  @include u-disabled;

  &.usa-button--hover,
  &.usa-button--active {
    @include u-disabled;
  }

  &:not(.usa-button--unstyled) {
    @include u-disabled-high-contrast-border;
  }
}

@use "uswds-core" as *;

$background-context: "Background";

.usa-dark-background {
  background-color: color("base-darker");

  p,
  span {
    color: color($theme-text-reverse-color);
  }

  a {
    @include set-link-from-bg(
      "base-darker",
      $theme-link-reverse-color,
      $context: $background-context
    );
  }
}

@use "sass:list";
@use "../../functions" as *;

// Outputs line-height

@mixin u-line-height($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  $family: list.nth($value, 1);
  $scale: list.nth($value, 2);
  line-height: lh($family, $scale) #{$important};
}

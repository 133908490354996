@use "sass:list";

@use "uswds-core" as *;

$summary-box-context: "Summary Box";

.usa-summary-box {
  @include typeset($theme-summary-box-font-family);
  @include border-box-sizing;
  @include set-text-and-bg(
    $theme-summary-box-background-color,
    $theme-summary-box-text-color,
    $context: $summary-box-context
  );
  @include u-radius($theme-summary-box-border-radius);
  border: $theme-summary-box-border-width color($theme-summary-box-border-color)
    solid;
  padding: units(3);
  position: relative;

  .usa-list {
    &:last-child {
      margin-bottom: 0;
    }
  }

  * + & {
    margin-top: units(2);
  }
}

.usa-summary-box__heading {
  @include typeset($theme-summary-box-font-family, "lg", 2);
  margin-top: 0;
  margin-bottom: units(1);
}

.usa-summary-box__text {
  @include u-margin-y(0);
}

.usa-summary-box__text:only-child {
  margin-bottom: $theme-summary-box-border-width;
  padding-top: units(0.5);
}

.usa-summary-box__link {
  @include set-link-from-bg(
    $theme-summary-box-background-color,
    $theme-summary-box-link-color,
    $context: $summary-box-context
  );
  &[href^="#"] {
    $link-tokens: get-link-tokens-from-bg(
      $theme-summary-box-background-color,
      $theme-summary-box-link-color,
      $context: $summary-box-context
    );
    &:visited {
      color: color(list.nth($link-tokens, 1));
    }
    &:hover {
      color: color(list.nth($link-tokens, 2));
    }
  }
}

@use "uswds-core" as *;

.usa-embed-container {
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.usa-embed-container {
  @include add-aspect("16x9");
  max-width: 100%;
}

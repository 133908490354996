@use "uswds-core" as *;

// PROCESS LIST
$theme-process-list-counter-content-padding: calc(
  (#{units($theme-process-list-counter-size)} / 2) - #{units(0.5)}
);
$theme-process-list-counter-line-x-position: calc(
  (
    (#{units($theme-process-list-counter-size)} / 2) -
      (#{units($theme-process-list-connector-width)} / 2)
  )
);

@include override-prose {
  .usa-process-list {
    @include typeset($theme-process-list-font-family);
    @include unstyled-list;
    counter-reset: usa-numbered-list;
    padding: 20px 0 0 $theme-process-list-counter-line-x-position;
    position: relative;

    // Use increased specificity to override unstyled-list()
    & > .usa-process-list__item {
      @include u-measure(5);
    }
  }

  .usa-process-list__item {
    border-left: units($theme-process-list-connector-width) solid
      color($theme-process-list-connector-color);
    font-size: size(
      $theme-process-list-font-family,
      $theme-process-list-font-size
    );
    margin-bottom: 0;
    padding-bottom: units(4);
    padding-left: calc(#{$theme-process-list-counter-content-padding} * 2);
  }
}

.usa-process-list__item {
  &:last-child {
    border-left: units($theme-process-list-connector-width) solid transparent;
  }

  &::before {
    @include u-flex("row", "align-center", "justify-center");
    $half-of-font-size: -0.4rem;
    @include u-circle($theme-process-list-counter-size);
    background-color: color($theme-process-list-counter-background-color);
    border: units($theme-process-list-counter-border-width) solid
      color($theme-process-list-counter-border-color);
    box-shadow: 0 0 0 units($theme-process-list-counter-gap-width)
      color($theme-process-list-counter-gap-color);
    color: color($theme-process-list-counter-text-color);
    content: counter(usa-numbered-list, decimal);
    counter-increment: usa-numbered-list;
    display: flex;
    font-family: family($theme-process-list-counter-font-family);
    font-size: size(
      $theme-process-list-counter-font-family,
      $theme-process-list-counter-font-size
    );
    font-weight: font-weight("bold");
    height: units($theme-process-list-counter-size);
    left: 0;
    line-height: lh($theme-process-list-counter-font-family, 1);
    margin-top: $half-of-font-size;
    position: absolute;
    width: units($theme-process-list-counter-size);
  }

  ul {
    list-style-type: disc;

    li {
      margin-bottom: units(0.5);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.usa-process-list__heading {
  @include typeset(
    $theme-process-list-heading-font-family,
    $theme-process-list-heading-font-size,
    2
  );
  color: color($theme-process-list-heading-color);
  font-family: family($theme-process-list-heading-font-family);
  font-weight: font-weight("bold");
  margin: 0;

  & + * {
    margin-top: units(0.5);
  }
}

// Apply a natural box layout model to all elements, but allowing components to
// change

@use "uswds-core/src/styles/settings" as *;

@if $theme-global-border-box-sizing {
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

@use "sass:list";
@use "../../functions" as *;

// Outputs border-radius

@mixin u-radius($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  border-radius: get-uswds-value("border-radius", $value) #{$important};
}

@mixin u-radius-bottom($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  border-bottom-left-radius: get-uswds-value("border-radius", $value) #{$important};
  border-bottom-right-radius: get-uswds-value("border-radius", $value) #{$important};
}

@mixin u-radius-left($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  border-bottom-left-radius: get-uswds-value("border-radius", $value) #{$important};
  border-top-left-radius: get-uswds-value("border-radius", $value) #{$important};
}

@mixin u-radius-right($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  border-bottom-right-radius: get-uswds-value("border-radius", $value) #{$important};
  border-top-right-radius: get-uswds-value("border-radius", $value) #{$important};
}

@mixin u-radius-top($value...) {
  $value: unpack($value);
  $important: null;
  @if has-important($value) {
    $value: remove($value, "!important");
    @if list.length($value) == 1 {
      $value: de-list($value);
    }
    $important: " !important";
  }
  border-top-left-radius: get-uswds-value("border-radius", $value) #{$important};
  border-top-right-radius: get-uswds-value("border-radius", $value) #{$important};
}

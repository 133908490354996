@use "uswds-core" as *;

.usa-checklist {
  @include add-list-reset;
  @include border-box-sizing;
  @include typeset;
}

.usa-checklist__item {
  @include add-checkbox-placeholder;
  @include u-margin-y(0);
  margin-bottom: 0;
  margin-top: units(1);

  // Deprecate usa-checklist__item--checked once the js is updated
  &.usa-checklist__item--checked {
    @include add-success-mark;
  }
}
